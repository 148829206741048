.asset-uploader .au_fileinput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.asset-uploader .au_fileinput + label {
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
}

.asset-uploader .au_fileinput + label:hover {
  color: black;
}

.asset-uploader .au_label {
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.asset-uploader .au_hint {
  border-bottom: 1px solid;
  height: inherit;
  padding-bottom: 5px;
  vertical-align: top;
  display: inline-flex;
  min-width: 0;
  flex-direction: column;
  flex: 1 1;
  width: calc(100% - 48px);
  margin-top: 12px;
}

.asset-uploader svg {
  flex: 0 0 auto;
  padding: 12px;
  overflow: visible;
  position: relative;
}

.asset-uploader svg:hover {
  background-color: rgba(170, 170, 170, 0.3);
  border-radius: 25px;
}

.asset-uploader .au_response,
.au_error,
.au_loading {
  font-size: 0.75em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.asset-uploader .au_error {
  color: red;
}

.asset-uploader .au_retry {
  border: none;
  background-color: gray;
  color: white;
  padding: 0.5em 1em;
  font-size: 0.75em;
  vertical-align: top;
}

.asset-uploader .au_retry:hover {
  background-color: darkgray;
}
